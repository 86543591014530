import React from 'react';
import guideImage from '../Unofficial Guide-The Villages V2.jpg'; 
import '../Guide.css';

const Guide = () => {
  return (
    <div className="guide-container">
      <h1>Unofficial Guide - The Villages</h1>
      <img src={guideImage} alt="Unofficial Guide to The Villages Icon" className="guide-image" />
      
      <p>
        <strong>Version 2</strong> is now available on both the Apple App Store and Google Play Store. Download the latest version to explore all the new features and improvements.
      </p>
      
      <div className="download-section">
        <h2>Download from:</h2>
        <ul>
          <li>
            <a
              href="https://apps.apple.com/us/app/unofficial-guide/id6593660910"
              target="_blank"
              rel="noopener noreferrer"
            >
              Apple App Store
            </a>
          </li>
          <li>
            <a
              href="https://play.google.com/store/apps/details?id=com.unofficialsoftware.guidetothevillages&pcampaignid=web_share"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Play Store
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Guide;